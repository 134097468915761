import { Controller } from "@hotwired/stimulus"

import moment from 'moment';

export default class extends Controller {
  static targets = ["dateInput"];
  static values = {
    autosave: String,
    defaultDate: String,
    format: String,
    keepInvalid: Boolean,
    locale: String,
    maxDate: String,
    minDate: String,
    useCurrent: String,
    verticalPosition: String,
    inScreener: Boolean,
  }

  connect() {
    $(this.dateInputTarget).attr('placeholder', this.format);
    $(this.dateInputTarget).datetimepicker({
      keepInvalid: this.keepInvalidValue,
      defaultDate: this.defaultDate,
      minDate: this.minDate,
      maxDate: this.maxDate,
      format: this.format,
      useCurrent: this.useCurrent,
      widgetPositioning: {
        vertical: this.verticalPositionValue || 'auto'
      },
      locale: this.localeValue || moment.locale(),
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', ({ date, oldDate }) => {
      let formattedDate = moment(date).format(this.format)
      this.dateInputTarget.value = formattedDate

      if (this.autosaveController && this.autosave) {
        this.autosaveController.save();
      }

      if (this.inScreenerValue) {
        const form = document.getElementById("screener-attempt-form-id")
        form.submit()
      }
    })
  }

  disconnect() {
    $(this.dateInputTarget).data("DateTimePicker").destroy();
  }

  get defaultDate() {
    return Boolean(this.defaultDateValue) ? new Date(JSON.parse(this.defaultDateValue)) : false
  }

  get minDate() {
    return Boolean(this.minDateValue) ? new Date(JSON.parse(this.minDateValue)) : false
  }

  get maxDate() {
    return Boolean(this.maxDateValue) ? new Date(JSON.parse(this.maxDateValue)) : false
  }

  get autosave() {
    return this.autosaveValue === 'off' ? false : true
  }

  get useCurrent() {
    return this.useCurrentValue === 'off' ? false : true
  }

  get format() {
    return this.formatValue || 'YYYY-MM-DD'
  }

  get autosaveController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("autosave"), "autosave")
  }
}
